var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "px-4 py-4 list-item-component",
      on: { click: _vm.displayPrompt },
    },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          {
            staticClass:
              "vx-col w-full sm:w-5/6 flex sm:items-center sm:flex-row flex-col",
          },
          [
            _c(
              "div",
              { staticClass: "flex items-center" },
              [
                _c("vs-checkbox", {
                  staticClass: "w-8 m-0 vs-checkbox-small",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                  model: {
                    value: _vm.isDone,
                    callback: function ($$v) {
                      _vm.isDone = $$v
                    },
                    expression: "isDone",
                  },
                }),
                _c(
                  "h6",
                  {
                    staticClass: "todo-title",
                    class: { "line-through": _vm.isDone },
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "todo-tags sm:ml-2 sm:my-0 my-2 flex" },
              _vm._l(_vm.tags, function (tag, index) {
                return _c("vs-chip", { key: index }, [
                  _c("div", {
                    staticClass: "h-2 w-2 rounded-full mr-1",
                    class: "bg-" + _vm.todoLabelColor(tag),
                  }),
                  _c("span", [_vm._v(_vm._s(_vm._f("capitalize")(tag)))]),
                ])
              }),
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full sm:w-1/6 ml-auto flex sm:justify-end" },
          [
            _c("feather-icon", {
              staticClass: "cursor-pointer",
              attrs: {
                icon: "InfoIcon",
                svgClasses: [
                  { "text-success stroke-current": _vm.isImportant },
                  "w-5",
                  "h-5 mr-4",
                ],
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleIsImportant.apply(null, arguments)
                },
              },
            }),
            _c("feather-icon", {
              staticClass: "cursor-pointer",
              attrs: {
                icon: "StarIcon",
                svgClasses: [
                  { "text-warning stroke-current": _vm.isStarred },
                  "w-5",
                  "h-5 mr-4",
                ],
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleIsStarred.apply(null, arguments)
                },
              },
            }),
            !_vm.isTrashed
              ? _c("feather-icon", {
                  staticClass: "cursor-pointer",
                  attrs: { icon: "TrashIcon", svgClasses: "w-5 h-5" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.moveToTrash.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "p",
            {
              staticClass: "mt-2 truncate",
              class: { "line-through": _vm.isDone },
            },
            [_vm._v(_vm._s(_vm.desc))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }