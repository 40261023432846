var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "todo__filters-container" },
    [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c(
          "div",
          {
            staticClass: "flex cursor-pointer",
            class: { "text-primary": _vm.todoFilter == "all" },
            on: {
              click: function ($event) {
                return _vm.applyTodoFilter("all")
              },
            },
          },
          [
            _c("feather-icon", {
              attrs: {
                icon: "MailIcon",
                svgClasses: [
                  { "text-primary stroke-current": _vm.todoFilter == "all" },
                  "h-6 w-6",
                ],
              },
            }),
            _c("span", { staticClass: "text-lg ml-3" }, [_vm._v("All")]),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "px-6 py-4" },
        [
          _c("h5", [_vm._v("Filters")]),
          _vm._l(_vm.todoFilters, function (filter) {
            return [
              _c(
                "div",
                {
                  key: filter.filter,
                  staticClass: "flex mt-6 cursor-pointer",
                  class: { "text-primary": _vm.todoFilter == filter.filter },
                  on: {
                    click: function ($event) {
                      return _vm.applyTodoFilter(filter.filter)
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: filter.icon,
                      svgClasses: [
                        {
                          "text-primary stroke-current":
                            _vm.todoFilter == filter.filter,
                        },
                        "h-6 w-6",
                      ],
                    },
                  }),
                  _c("span", { staticClass: "text-lg ml-3" }, [
                    _vm._v(_vm._s(filter.filterName)),
                  ]),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h5", [_vm._v("Labels")]),
        _c(
          "div",
          { staticClass: "todo__lables-list" },
          _vm._l(_vm.todoTags, function (tag, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass:
                  "todo__label flex items-center mt-6 cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.applyTodoFilter(tag.value)
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "h-4 w-4 rounded-full mr-4",
                  class: "bg-" + tag.color,
                }),
                _c(
                  "span",
                  {
                    staticClass: "text-lg",
                    class: { "text-primary": _vm.todoFilter == tag.value },
                  },
                  [_vm._v(_vm._s(tag.text))]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }